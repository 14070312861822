import axios from "axios";
import { API_URL } from "@/settings";

const instance = axios.create({
  baseURL: API_URL + "api/auth",
});
// Adicione o cabeçalho "APP" à instância
instance.defaults.headers.common["APP"] = "Backend";

export default instance;
