<!-- eslint-disable max-len -->
<template>
  <v-toolbar height="80" color="white" dark app fill-height align-center id="Header">
    <v-layout row wrap align-center>
      <v-flex xs6>
        <v-layout row wrap align-center>
          <img :src="`/assets/imgs/header-logo.png`" height="60" />
          <v-flex xs1 class="text-xs-center mx-2">
            <!-- v-slot:activator="{ on }" -->
            <v-menu min-width="325" content-class="courses_dropdown" offset-y bottom left nudge-bottom="-2px" nudge-right="50">
              <template v-slot:activator="{ on }">
                <v-btn class="p-0" outline fab flat color="grey lighten-1" v-on="on">
                  <v-icon class="icon-add"></v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="primary white--text text-center courses_dropdown_items">
                  <h6 class="size--s21 text-white w-100 d-block">Adicionar</h6>
                  <p class="size--s14 text-white w-100 d-block">Rápido</p>
                </v-card-title>
                <v-card-text>
                  <div class="quick-create-actions">
                    <v-layout wrap class="text-center" justify-space-around>
                      <v-flex v-for="quickAction in quickActionsFiltered" :key="quickAction.title" xs6 class="box">
                        <button @click="quickInscriptionDialog = true" v-if="quickAction.path === 'inscriptions' &&
            checkRoleAccess(quickAction.roles)
            ">
                          <v-icon class="size--s40 my-3" :class="quickAction.icon"></v-icon>
                          <p class="size--s14 font-weight-bold text--dark">
                            {{ quickAction.title }}
                          </p>
                        </button>
                        <button @click="quickClassDialog = true" v-else-if="quickAction.path === 'classes' &&
            checkRoleAccess(quickAction.roles)
            ">
                          <v-icon class="size--s40 my-3" :class="quickAction.icon"></v-icon>
                          <p class="size--s14 font-weight-bold text--dark">
                            {{ quickAction.title }}
                          </p>
                        </button>

                        <button @click="onQuickActionNavigate(quickAction)" v-else-if="checkRoleAccess(quickAction.roles)">
                          <v-icon class="size--s40 my-3" :class="quickAction.icon"></v-icon>
                          <p class="size--s14 font-weight-bold text--dark">
                            {{ quickAction.title }}
                          </p>
                        </button>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>
          <v-flex>
            <v-text-field id="Input-form" @keyup.enter="onTyping" @click:clear="
            searchResults = [];
          dialog = false;
          " type="text" name="Search" @blur="$v.searchQuery.$touch()" :error="$v.searchQuery.$error" v-model="searchQuery" label="Outline" single-line outline clearable light flat color="grey lighten-1" placeholder="Pesquisar" prepend-inner-icon="search"></v-text-field>
            <div class="searching">
              <v-dialog v-model="dialog" full-width attach=".searching" :hide-overlay="true" scrollable transition="slide-x-transition">
                <v-card>
                  <v-list>
                    <div class="custom-container">
                      <v-layout row wrap>
                        <v-flex offset-xs2 xs8>
                          <div class="box__item">
                            <template v-for="item in this.searchResults">
                              <v-list-tile :to="SearchRoute(item.Type) + item.Id" :key="item.Id">
                                <i :class="item.Icon" class="mr-3"></i>
                                <v-list-tile-content>
                                  <v-list-tile-title>{{
            item.Name
          }}</v-list-tile-title>
                                </v-list-tile-content>
                              </v-list-tile>
                            </template>
                          </div>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-list>
                </v-card>
              </v-dialog>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 justify-end>
        <v-layout v-if="user" row wrap class="position-relative">
          <v-flex xs12 class="text-right">
            <v-menu min-width="325" content-class="profile_dropdown" offset-y bottom nudge-bottom="-2px" nudge-right="20" left>
              <template v-slot:activator="{ on }">
                <span class="size--s14 font-weight-light mr-3 info--text">
                  Bem Vindo,
                  <strong class="font-weight-light size--s14 black--text">{{
            user.Name
          }}</strong>
                </span>
                <v-chip color="primary" v-on="on">
                  <v-avatar size="45">
                    <img :src="GetRelativePath(user.Photo)" alt="Foto de utilizador" />
                  </v-avatar>
                  <v-icon class="icon-arrow-down"></v-icon>
                </v-chip>
              </template>
              <v-card class="popUp__user">
                <v-card-title class="primary white--text">
                  <figure class="d-flex align-center">
                    <div class="img-profile-rounded">
                      <img :src="GetRelativePath(user.Photo, 'h=50&h=50&mode=crop')
            " alt="foto user" title="foto user" />
                    </div>
                    <figcaption class="ml-3">
                      <div class="size--s18 text-light">
                        {{ user.Name }}
                      </div>
                      <div class="size--s12 text-white">{{ user.Email }}</div>
                      <div class="size--s12 text-white">{{ user.Code }}</div>
                    </figcaption>
                  </figure>
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-tile :href="frontEndUrl" target="blank">
                      <v-list-tile-avatar class="pl-3">
                        <v-icon class="icon-user size--s18"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="info--text">Portal de formação</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile to="/myaccount">
                      <v-list-tile-avatar class="pl-3">
                        <v-icon class="icon-user size--s18"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="info--text">Meu perfil</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile to="/myaccount/security">
                      <v-list-tile-avatar class="pl-3">
                        <v-icon class="icon-lock size--s18 mx-5"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="info--text">Segurança</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile to="/myaccount/history">
                      <v-list-tile-avatar class="pl-3">
                        <v-icon class="icon-tasks size--s18 mx-5"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="info--text">Histórico</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <!-- TODO after.. -->
                    <!-- <v-list-tile to="#">
                      <v-list-tile-avatar class="pl-3">
                        <v-icon class="icon-message size--s18 mx-5"></v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title class="info--text"
                          >Mensagens</v-list-tile-title
                        >
                      </v-list-tile-content>
                    </v-list-tile> -->
                    <v-list-tile class="pl-3">
                      <div class="ml-3">
                        <v-btn class="btn btn-logout" outline flat color="grey lighten-1" @click.prevent="logout()">Sair</v-btn>
                      </div>
                    </v-list-tile>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-flex>
          <!-- <v-flex xs2 class="text-xs-center">
            <v-btn class="p-0 mr-2" outline fab flat color="grey lighten-1">
              <v-icon class="icon-notifications"></v-icon>
            </v-btn>
          </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
    <quick-class-modal v-model="quickClassDialog"></quick-class-modal>
    <quick-inscription-modal v-model="quickInscriptionDialog"></quick-inscription-modal>
  </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as types from "@/store/types";
import { FRONTEND_URL } from "@/settings";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import searchService from "@/services/api/searchService";
import subscriptionsService from "@/services/api/userCourseSubscriptionsService";
import { minLength, required } from "vuelidate/lib/validators";
import UrlBuilder from "@/utilities/url-builder";
import router from "@/router/router";
import QuickClassModal from "@/components/modals/quick-class-modal";
import QuickInscriptionModal from "@/components/modals/quick-inscription-modal";
import { layoutServiceBus } from "@/services/bus/layoutServiceBus";

export default {
  components: {
    "quick-class-modal": QuickClassModal,
    "quick-inscription-modal": QuickInscriptionModal,
  },
  data () {
    return {
      frontEndUrl: FRONTEND_URL,
      quickInscriptionDialog: false,
      quickClassDialog: false,
      dialog: false,
      searchQuery: "",
      searchResults: [],
      quickActions: [
        {
          title: "Turmas",
          path: "classes",
          subtitle: "Adicionar Nova",
          icon: "icon-list",
          help_icon: "icon-help",
          help_text: "Ajuda sobre Formações",
          help_description:
            "Poderá criar uma ação de formação de forma rápida.",
          roles: [2, 4, 5],
        },
        {
          title: "Inscrições",
          path: "inscriptions",
          subtitle: "Adicionar inscrições a ação de formação",
          icon: "icon-catalog-port",
          help_icon: "icon-help",
          help_text: "Ajuda sobre inscrições e presenças",
          help_description: "Poderá criar uma inscrição de forma rápida.",
          roles: [2, 4, 5],
        },
        {
          title: "Calendário de Formação",
          path: "/catalog/calendar",
          subtitle: "Consultar calendário de formações",
          icon: "icon-calendar",
          help_icon: "icon-help",
          help_text: "Ajuda sobre Calendário",
          help_description:
            "Poderá observar um calendário que contém as sessões de formação futuras.",
          roles: [2, 4, 5],
        },
        {
          title: "Relatórios",
          path: "/reports",
          subtitle: "Consultar relatórios e indicadores",
          icon: "icon-catalog-challenge",
          help_icon: "icon-help",
          help_text: "Ajuda sobre Relatórios",
          help_description:
            "Poderá elaborar e/ou observar diversos relatórios referentes à plataforma.",
          roles: [2, 3, 4, 5],
        },
      ],
    };
  },
  watch: {
    $route () {
      this.searchResults = [];
      this.dialog = false;
      this.searchQuery = "";
      var root = document.getElementsByTagName("html");
      root[0].style.overflow = "auto";
    },
  },
  computed: {
    quickActionsFiltered () {
      if (this.user) {
        var filtered = this.quickActions.filter((s) =>
          s.roles.includes(this.user.IdUserRole)
        );
        return filtered;
      }
      return this.quickActions;
    },
    ...mapGetters({
      user: types.GET_USER,
    }),
  },
  validations: {
    searchQuery: {
      minLength: minLength(1),
    },
    Instance: {
      Code: {
        required,
        minLength: minLength(1),
      },
    },
  },
  methods: {
    checkRoleAccess (roles) {
      var role = 0;
      if (this.user) {
        role = this.user.IdUserRole;
        return roles.find(function (element) {
          return element == role;
        })
          ? true
          : false;
      }
      return false;
    },
    async confirmAction () {
      await subscriptionsService
        .CreateByCodes(
          this.Instance.Code.split("\n"),
          this.Instance.IdCourse,
          this.Instance.IdCourseClass != null && this.Instance.IdCourseClass > 0
            ? this.Instance.IdCourseClass
            : null
        )
        .then((res) => {
          notificationServiceBus.showInfoMessage(
            "Criação concluida",
            `<p><strong>Efectou a inscrição com successo</strong> ${res.data.UsersInserted.length}</p>
           <p><strong>Incrições ativas</strong> ${res.data.UsersIgnored.length}</p>`
          );
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    ...mapActions({
      logout: types.USER_LOGOUT,
    }),
    onQuickActionNavigate (link) {
      router.push(`${link.path}#${new Date().getTime()}`);
    },
    GetRelativePath (path) {
      return `${UrlBuilder.GetPath(path)}`;
    },
    SearchRoute (type) {
      if (type.toLowerCase() === "users") {
        return "/users/";
      } else if (type.toLowerCase() === "courses") {
        return "/catalog/courses/";
      } else if (type.toLowerCase() === "portfolios") {
        return "/catalog/portfolios/";
      }
    },
    async onTyping () {
      var root = document.getElementsByTagName("html");
      root[0].style.overflow = "hidden";
      if (this.searchQuery.length >= 1) {
        this.dialog = true;
        await searchService
          .Search(this.searchQuery)
          .then((response) => {
            this.searchResults = response.data;
          })
          .catch((error) => {
            this.searchResults = [];
            notificationServiceBus.showError(error.response);
          });
      } else {
        this.dialog = false;
        this.searchResults = [];
      }
    },
  },

  created () {
    layoutServiceBus.$on("openQuickActionCourseClass", () => {
      this.quickClassDialog = true;
    });
    layoutServiceBus.$on("openQuickActionBatchInscriptions", () => {
      this.quickInscriptionDialog = true;
    });
  },
};
</script>

<style scoped lang="scss"></style>
