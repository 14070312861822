// Getters
export const GET_IS_AUTHENTICATED = "shared/GET_IS_AUTHENTICATED";
export const GET_USER = "shared/GET_USER";
export const GET_TOKEN = "shared/GET_TOKEN";
export const GET_AREAS = "shared/GET_AREAS";
export const GET_USER_GROUPS = "shared/GET_USER_GROUPS";
export const GET_USER_ROLES = "shared/GET_USER_ROLES";
export const GET_LANGUAGES = "shared/GET_LANGUAGES";
export const GET_USER_ROLE = "shared/GET_USER_ROLE";
export const IS_USER_ADMIN = "shared/IS_USER_ADMIN";

// Mutations
export const MUTATE_CLEAR_STORE = "shared/MUTATE_CLEAR_STORE";
export const MUTATE_USER_INJECT = "shared/MUTATE_USER_INJECT";
export const MUTATE_USER_REMOVE = "shared/MUTATE_USER_REMOVE";
export const MUTATE_AREAS = "shared/MUTATE_AREAS";
export const MUTATE_USER_GROUPS = "shared/MUTATE_USER_GROUPS";
export const MUTATE_USER_ROLES = "shared/MUTATE_USER_ROLES";
export const MUTATE_LANGUAGES = "shared/MUTATE_LANGUAGES";

// Actions
export const USER_LOGIN = "shared/USER_LOGIN";
export const USER_AUTOLOGIN = "shared/USER_AUTOLOGIN";
export const USER_LOGOUT = "shared/USER_LOGOUT";
export const STORE_USER = "shared/STORE_USER";
export const STORE_USER_GROUPS = "shared/STORE_USER_GROUPS";
export const STORE_USER_ROLES = "shared/STORE_USER_GROUPS";
export const STORE_LANGUAGES = "shared/STORE_LANGUAGES";

export const GLOBAL_USER_TOKEN = "token";
