var moment = require("moment");
const Dates = {
  GetDaysDifference(start, end) {
    return Math.floor(
      (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  },
  ConvertToDate(dt) {
    var st = dt.split(" ")[0].split("-");
    return new Date(
      parseInt(st[2]),
      parseInt(st[1]) - 1,
      parseInt(st[0]),
      0,
      0,
      0
    );
  },
  PresentDateWithFormat(dt, CurrentFormat, Finalformat) {
    return moment(dt, CurrentFormat).format(Finalformat);
  },

  IncrementDateWithFormat(dt, CurrentFormat, Finalformat, type, increment) {
    return moment(dt, CurrentFormat).add(increment, type).format(Finalformat);
  },

  DecrementDateWithFormat(dt, CurrentFormat, Finalformat, type, increment) {
    return moment(dt, CurrentFormat)
      .subtract(increment, type)
      .format(Finalformat);
  },

  PresentToDate(dt) {
    const day = dt.getDate().toString();
    const month = (dt.getMonth() + 1).toString();
    const year = dt.getFullYear().toString();
    return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
  },
};

export default Dates;
