import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const instance = axios.create();

const baseUrl = API_URL + "api";

const endPoints = {
  Search: baseUrl + "/search?q=",
};

instance.defaults.headers.common[
  "Authorization"
] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`;
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Search(query) {
    return instance.get(endPoints.Search + query, {
      query,
    });
  },
};

export default actions;
