import * as types from "./types";

export default {
  [types.GET_USER_ROLES]: (state) => {
    return state.UserRoles;
  },
  [types.GET_USER_GROUPS]: (state) => {
    return state.UserGroups;
  },
  [types.GET_USER]: (state) => {
    return state.User;
  },
  [types.GET_LANGUAGES]: (state) => {
    return state.Languages;
  },
  [types.GET_AREAS]: (state) => {
    return state.Areas;
  },
  [types.IS_USER_ADMIN]: (state) => {
    return state.User && state.User.IdUserRole == 5;
  },
  [types.GET_USER_ROLE]: (state) => {
    if (!state.User) return 0;
    return state.User.IdUserRole;
  },
};
