<template>
  <v-dialog width="800" ref="modal" v-model="value" persistent="">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Adicionar Ação de formação
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md text-xs-center>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                label="Nome da Ação de Formação"
                v-model="Instance.Name"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <course-selector
                v-model="Instance.IdCourse"
                label="Código da formação"
              ></course-selector>
            </v-flex>
            <v-flex xs12>
              <job-location-selector
                v-model="Instance.IdJobLocation"
              ></job-location-selector>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                type="number"
                step="1"
                min="0"
                label="Limite de Inscrições"
                v-model="Instance.MaxInscriptions"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-text-field
                label="Minutos"
                type="number"
                step="1"
                min="0"
                v-model="Minutes"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                :items="Trainers"
                label="Formadores"
                item-text="Name"
                v-model="Instance.Formers"
                item-value="IdUser"
                :multiple="true"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn flat @click="closeModal()">Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          flat
          :loading="isLoading"
          @click="confirmAction()"
          :disabled="$v.Instance.$touch() || $v.Instance.$error"
          >Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CoursesSelector from "@/components/selectors/courses";
import { minLength, required } from "vuelidate/lib/validators";
import joblocationSelector from "@/components/selectors/job-locations";
import usersService from "@/services/api/usersService";
import courseClassesService from "@/services/api/courseClassesService";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import coursesService from "@/services/api/coursesService";
export default {
  props: ["value"],
  data() {
    return {
      Course: {},
      isLoading: false,
      Trainers: [],
      Instance: {},
    };
  },
  components: {
    "course-selector": CoursesSelector,
    "job-location-selector": joblocationSelector,
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    "Instance.IdCourse": function (val) {
      if (val > 0) {
        this.LoadCourse();
      } else {
        this.Course = {};
      }
    },
  },
  computed: {
    Minutes: function () {
      return this.Course && this.Course.MinutesFormation > 0
        ? this.Course.MinutesFormation
        : this.Instance.Minutes;
    },
  },
  methods: {
    async LoadCourse() {
      await coursesService
        .GetSingle(this.Instance.IdCourse, { Detailed: false })
        .then((response) => (this.Course = response.data.Instance))
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async confirmAction() {
      if (this.isLoading) return false;
      this.isLoading = true;
      await courseClassesService
        .Create(this.Instance)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Criou a Ação de Formação com sucesso!"
          );
          this.reset();
          this.closeModal();
        })
        .catch((error) => {
          this.isLoading = false;
          notificationServiceBus.showError(error.response);
        })
        .finally(() => (this.isLoading = false));
    },
    async loadTrainers() {
      await usersService
        .Get({ Filters: [{ Field: "IdUserRole", Operation: "eq", Value: 2 }] })
        .then((res) => {
          this.Trainers = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    closeModal() {
      this.$emit("input", false);
    },
    reset() {
      this.Instance = {};
    },
  },
  async created() {
    await this.loadTrainers();
  },
};
</script>
