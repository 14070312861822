import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const baseUrl = API_URL + "api/usercoursesubscriptions";

const instance = axios.create();

const endPoints = {
  Get: baseUrl + "/fetch",
  Alerts: baseUrl + "/alerts",
  Update: baseUrl + "/update",
  Create: baseUrl + "/create",
  CreateByCode: baseUrl + "/createbycode",
  CreateByBatch: baseUrl + "/createbybatch",
  UpdateByCourseClass: baseUrl + "/updatebycourseclass",
  CreateByCourseSession: baseUrl + "/createbycoursesession",
  Delete: baseUrl + "/delete",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Get(options) {
    return instance.post(endPoints.Get, {
      ...{
        Filters: [],
      },
      ...options,
    });
  },
  Alerts(IdCourseClass, options) {
    const data = {
      IdCourseClass: IdCourseClass,
      ...options,
    };
    return instance.post(endPoints.Alerts, data);
  },
  Delete(id, options) {
    const data = {
      ...options,
    };
    return instance.delete(endPoints.Delete + "/" + id, {
      data,
    });
  },
  Update(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.put(endPoints.Update, data);
  },
  Create(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.post(endPoints.Create, data);
  },
  CreateByCodes(codes, courseId, classId) {
    const data = {
      Codes: codes,
      IdCourse: courseId,
      IdCourseClass: classId,
      Link:
        "https://forma.vorwerk.pt/courses/detail/" + courseId + "/dashboard",
    };
    return instance.post(endPoints.CreateByCode, data);
  },
  CreateByBatch(
    idCourse,
    idClass,
    idCourseSession,
    idLocaction,
    idCostcenter,
    idFunction,
    idJobDepartment,
    idUserGroup
  ) {
    const data = {
      IdCourse: idCourse,
      IdCourseClass: idClass,
      IdCourseSession: idCourseSession,
      IdJobLocation: idLocaction,
      IdJobCostCenter: idCostcenter,
      IdJobFunction: idFunction,
      IdJobDepartment: idJobDepartment,
      idUserGroup: idUserGroup,
    };
    return instance.post(endPoints.CreateByBatch, data);
  },
  UpdateByCourseClass(idClass, subs) {
    const data = {
      IdCourseClass: idClass,
      Instance: subs,
    };
    return instance.post(endPoints.UpdateByCourseClass, data);
  },
  CreateByCourseSession(codes, sessionid) {
    const data = {
      Codes: codes,
      IdCourseSession: sessionid,
    };
    return instance.post(endPoints.CreateByCourseSession, data);
  },
};

export default actions;
