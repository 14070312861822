/* eslint-disable prettier/prettier */
export const DashboardView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/dashboard/index.vue");
export const HelpView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/help/index.vue");
export const MyAccountIndexView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/myaccount/index.vue");
export const MyAccountProfileView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/myaccount/detail/profile.vue");
export const MyAccountHistoryView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/myaccount/detail/history.vue");
export const MyAccountMessagesView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/myaccount/detail/messages.vue");
export const MyAccountSecurityView = () =>
  import(/* webpackChunkName: "core-module" */ "@/views/myaccount/detail/security.vue");
