/* eslint-disable prettier/prettier */
export const ConfigurationsJobLocationsIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/job-locations/index.vue");
export const ConfigurationsJobLocationGroupsIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/job-locations-groups/index.vue");
export const ConfigurationsJobFunctionIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/job-functions/index.vue");
export const ConfigurationsJobCostCentersIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/job-costcenters/index.vue");
export const ConfigurationsPortfolioCategoriesIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/portfolio-categories/index.vue");
export const ConfigurationsJobDepartmentsIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/job-departments/index.vue");
export const ConfigurationsCourseClassificationsIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/course-classifications/index.vue");
export const ConfigurationsUserDocumentCategoriesIndexView = () =>
  import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/user-documents-categories/index.vue");
  export const ConfigurationsSuppliersIndexView = () =>
    import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/suppliers/index.vue");
  // export const ConfigurationsJustificationIndexView = () =>
  //   import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/justifications/index.vue");
  // export const ConfigurationsAbsencesIndexView = () =>
  //   import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/absences/index.vue");
  export const ConfigurationsUserGroupIndexView = () =>
    import( /* webpackChunkName: "configurations-module" */ "@/views/configurations/user-groups/index.vue");