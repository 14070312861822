<template>
  <div>
    <app-header />
    <app-menu params="route: route" />
    <transition name="fade">
      <router-view
        id="Content"
        params="route: route"
        :class="{ collapsed: !isLeftMenuOpen }"
      />
    </transition>
    <app-footer />
  </div>
</template>

<script>
import * as types from "@/store/types";
import { layoutServiceBus } from "@/services/bus/layoutServiceBus";
import MenuComponent from "@/components/layout/menu";
import HeaderComponent from "@/components/layout/header";
import FooterComponent from "@/components/layout/footer";

export default {
  components: {
    "app-menu": MenuComponent,
    "app-header": HeaderComponent,
    "app-footer": FooterComponent,
  },
  data() {
    return {
      isLeftMenuOpen: false,
    };
  },
  created() {
    layoutServiceBus.$on("leftMenuToggled", (isOpen) => {
      this.isLeftMenuOpen = isOpen;
    });
    this.$store.dispatch(types.USER_AUTOLOGIN);
  },
};
</script>

<style></style>
