/* eslint-disable prettier/prettier */
export const UsersListingView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/index.vue");
export const UsersDetailView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail.vue");
export const UsersDetailDashboardView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/dashboard.vue");
export const UsersDetailCoursesView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/courses.vue");
export const UsersDetailReportsView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/reports.vue");
export const UsersDetailProfileView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/profile.vue");
export const UsersDetailEditView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/edit.vue");
export const UsersDetailSecurityView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/security.vue");
export const UsersDetailDocumentsView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/documents.vue");
export const UsersDetailAbsencesView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/listing/detail/absences.vue");
// export const UsersGamificationIndexView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/gamification/index.vue");
// export const UsersGamificationDashboardView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/gamification/detail/dashboard.vue");
// export const UsersGamificationBadgesView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/gamification/detail/badges.vue");
// export const UsersGamificationLogsView = () => import( /* webpackChunkName: "users-module" */ "@/views/users/gamification/detail/logs.vue");