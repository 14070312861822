import { API_URL } from "@/settings";

const UrlBuilder = {
  GetPath(path) {
    return API_URL.replace(new RegExp("[/]+$"), "") + path;
  },
  GetResizerOptions(width, height, mode) {
    return `w=${width}&h=${height}&mode=${mode}`;
  },
};

export default UrlBuilder;
