<template>
  <v-footer color="white" inset height="50">
    <v-container>
      <v-layout align-center>
        <v-flex xs6>
          {{ new Date().getFullYear() }} &copy; Todos os direitos reservados
        </v-flex>
        <v-flex xs6 class="text-xs-right">{{ enviroment }}</v-flex>
      </v-layout>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    enviroment() {
      return process.env.NODE_ENV;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.v-footer {
  height: $footer-height;
  z-index: 101;
}
.v-footer .container {
  padding: 0px 35px !important;
}
</style>
