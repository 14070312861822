import Vue from "vue";

export const layoutServiceBus = new Vue({
  methods: {
    leftMenuToggled(isOpen) {
      this.$emit("leftMenuToggled", isOpen);
    },
    openQuickActionCourseClass() {
      this.$emit("openQuickActionCourseClass", true);
    },
    openQuickActionBatchInscriptions() {
      this.$emit("openQuickActionBatchInscriptions", true);
    },
  },
});
