import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    User: null,
    Areas: null,
    UserGroups: null,
    UserRoles: null,
    Languages: null,
    Language: "pt",
  },
  getters,
  mutations,
  actions,
});
