import axios from "axios";
import { API_URL } from "@/settings";
import * as types from "@/store/types";

const baseUrl = API_URL + "api/users";

const instance = axios.create();

const endPoints = {
  Get: baseUrl + "/fetch",
  Update: baseUrl + "/update",
  Create: baseUrl + "/create",
  Delete: baseUrl + "/delete",
  UpdateSecurity: baseUrl + "/update-security",
  UserLogs: API_URL + "api/userlogs/fetch",
  FormativePorgression: baseUrl + "/formative-progression",
  UploadJustifications: baseUrl + "/justifications",
  UploadAbsences: baseUrl + "/absences",
};

// eslint-disable-next-line prettier/prettier
instance.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem(types.GLOBAL_USER_TOKEN)}`
instance.defaults.headers.common["Content-Type"] = "application/json";

const actions = {
  Get(options) {
    return instance.post(endPoints.Get, {
      ...{
        Filters: [],
      },
      ...options,
    });
  },
  GetSingle(id, options) {
    return instance.get(endPoints.Get + "/" + id, {
      params: options,
    });
  },
  GetUserLogs(options) {
    return instance.post(endPoints.UserLogs, {
      ...{
        Filters: [],
      },
      ...options,
    });
  },
  GetUserLocations(userId) {
    return instance.get(`${baseUrl}/${userId}/locations`);
  },
  GetUserFormativePorgression(id) {
    return instance.get(endPoints.FormativePorgression + "/" + id);
  },
  Delete(id, options) {
    const data = {
      ...options,
    };
    return instance.delete(endPoints.Delete + "/" + id, {
      data,
    });
  },
  Update(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.put(endPoints.Update, data);
  },
  UpdateSecurity(Password) {
    return instance.put(API_URL + "api/auth/update-security", {
      Password,
    });
  },
  UpdateData(data) {
    return instance.put(API_URL + "api/auth/update-data", data);
  },
  UpdateUserSecurity(Password, id) {
    return instance.put(endPoints.UpdateSecurity + "/" + id, {
      Password,
    });
  },
  Create(item, options = {}) {
    const data = {
      Instance: item,
      ...options,
    };
    return instance.post(endPoints.Create, data);
  },
  UploadJustifications(item, SkipFirstLine, options = {}) {
    const data = {
      File: item,
      SkipFirstLine: SkipFirstLine,
      ...options,
    };
    return instance.post(endPoints.UploadJustifications, data);
  },
  UploadAbsences(item, SkipFirstLine, DeleteExisting, options = {}) {
    const data = {
      File: item,
      SkipFirstLine: SkipFirstLine,
      DeleteExisting: DeleteExisting,
      ...options,
    };
    return instance.post(endPoints.UploadAbsences, data);
  },
};

export default actions;
